var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{attrs:{"py":"8","mx":{ base: '0', md: '0', xs: '5' }}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between","mb":"8"}},[_c('c-flex',{attrs:{"align":"baseline"}},[_c('c-heading',{attrs:{"fontSize":"3xl","fontWeight":"700","mr":"3"}},[_vm._v(" Calendar ")]),_c('c-flex',{attrs:{"as":"a","align":"center"}},[_c('c-text',{attrs:{"as":"span","color":"vc-orange.400","fontSize":"md","mr":"2","fontWeight":"600"}},[_vm._v(" Share ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '4',
            h: '4',
            fill: '#ef5323',
          }),expression:"{\n            w: '4',\n            h: '4',\n            fill: '#ef5323',\n          }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/share-box-fill.svg") + "#share"}})])],1)],1),_c('c-link',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
          w: '6',
          h: '6',
          fill: '#ef5323',
        }),expression:"{\n          w: '6',\n          h: '6',\n          fill: '#ef5323',\n        }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/notification-fill.svg") + "#notification"}})])])],1),_c('c-grid',{attrs:{"w":"100%","template-columns":{
      base: '250px 1fr',
      md: '250px 1fr',
      xs: 'repeat(1, 1fr)',
    },"gap":"2"}},[_c('c-stack',{attrs:{"spacing":8}},[_c('c-box',[_c('c-heading',{attrs:{"fontWeight":"600","fontSize":"sm","mb":"4"}},[_vm._v(" Milestones ")]),_c('c-stack',{attrs:{"spacing":2}},[_c('c-box',{attrs:{"w":"100%","position":"relative"}},[_c('label',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                display: 'flex',
                alignItems: 'center',
              }),expression:"{\n                display: 'flex',\n                alignItems: 'center',\n              }"}]},[_c('c-visually-hidden',{attrs:{"as":"input","type":"checkbox","default-checked":""}}),_c('c-control-box',{attrs:{"border-width":"2px","size":"20px","rounded":"sm","borderColor":"vc-orange.400","_checked":{
                  color: 'white',
                },"_checkedAndChild":{
                  display: 'block',
                },"_focus":{ boxShadow: 'none' }}},[_c('c-icon',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    display: 'none',
                  }),expression:"{\n                    display: 'none',\n                  }"}],attrs:{"name":"check","color":"vc-orange.400","size":"12px"}})],1),_c('c-box',{attrs:{"as":"span","ml":"3","fontSize":"xs","fontWeight":"500"}},[_vm._v(" Milestones ")])],1)]),_c('c-box',{attrs:{"w":"100%","position":"relative"}},[_c('label',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                display: 'flex',
                alignItems: 'center',
              }),expression:"{\n                display: 'flex',\n                alignItems: 'center',\n              }"}]},[_c('c-visually-hidden',{attrs:{"as":"input","type":"checkbox","default-checked":""}}),_c('c-control-box',{attrs:{"border-width":"2px","size":"20px","rounded":"sm","borderColor":"vc-orange.400","_checked":{
                  color: 'white',
                },"_checkedAndChild":{
                  display: 'block',
                },"_focus":{ boxShadow: 'none' }}},[_c('c-icon',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    display: 'none',
                  }),expression:"{\n                    display: 'none',\n                  }"}],attrs:{"name":"check","color":"vc-orange.400","size":"12px"}})],1),_c('c-box',{attrs:{"as":"span","ml":"3","fontSize":"xs","fontWeight":"500"}},[_vm._v(" Periods ")])],1)]),_c('c-box',{attrs:{"w":"100%","position":"relative"}},[_c('label',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                display: 'flex',
                alignItems: 'center',
              }),expression:"{\n                display: 'flex',\n                alignItems: 'center',\n              }"}]},[_c('c-visually-hidden',{attrs:{"as":"input","type":"checkbox","default-checked":""}}),_c('c-control-box',{attrs:{"border-width":"2px","size":"20px","rounded":"sm","borderColor":"vc-orange.400","_checked":{
                  color: 'white',
                },"_checkedAndChild":{
                  display: 'block',
                },"_focus":{ boxShadow: 'none' }}},[_c('c-icon',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    display: 'none',
                  }),expression:"{\n                    display: 'none',\n                  }"}],attrs:{"name":"check","color":"vc-orange.400","size":"12px"}})],1),_c('c-box',{attrs:{"as":"span","ml":"3","fontSize":"xs","fontWeight":"500"}},[_vm._v(" Module Reminders ")])],1)])],1)],1),_c('c-box',[_c('c-heading',{attrs:{"fontWeight":"600","fontSize":"sm","mb":"4"}},[_vm._v(" Categories ")]),_c('c-flex',[_c('c-flex',{attrs:{"color":"blue.300","minWidth":"120px","mr":"2","mb":"3","borderWidth":"1px","borderRadius":"4px","bg":"blue.50","align":"center","justify":"center","h":"30px","fontSize":"xs","fontWeight":"500"}},[_vm._v(" Tag name ")]),_c('c-flex',{attrs:{"color":"blue.300","minWidth":"120px","mr":"2","mb":"3","borderWidth":"1px","borderRadius":"4px","bg":"blue.50","align":"center","justify":"center","h":"30px","fontSize":"xs","fontWeight":"500"}},[_vm._v(" New Tag name ")])],1)],1),_c('c-box',[_c('c-heading',{attrs:{"fontWeight":"600","fontSize":"sm","mb":"4"}},[_vm._v(" Type ")]),_c('c-flex',{attrs:{"flexWrap":"wrap"}},[_c('c-flex',{attrs:{"px":"2","mr":"2","mb":"3","minWidth":"120px","color":"#000","borderWidth":"1px","borderRadius":"4px","bg":"#fff","align":"center","justify":"center","h":"30px","fontSize":"xs","fontWeight":"500","boxShadow":"0px 1px 6px rgba(0, 0, 0, 0.16)"}},[_vm._v(" Annual General Meeting ")]),_c('c-flex',{attrs:{"px":"2","mr":"2","mb":"3","color":"#000","minWidth":"120px","borderWidth":"1px","borderRadius":"4px","bg":"#fff","align":"center","justify":"center","h":"30px","fontSize":"xs","fontWeight":"500","boxShadow":"0px 1px 6px rgba(0, 0, 0, 0.16)"}},[_vm._v(" New Tag name ")])],1)],1)],1),_c('c-box',[_c('c-flex',{attrs:{"justify":"flex-end","mb":"4"}},[_c('c-link',{attrs:{"display":"flex","alignItems":"center"}},[_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"sm","fontWeight":"600","mr":"2"}},[_vm._v(" Add ")]),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
              width: '25px',
              height: '25px',
            }),expression:"{\n              width: '25px',\n              height: '25px',\n            }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/add-circle-line.svg") + "#add-circle"}})])],1)],1),_c('c-box',{attrs:{"w":"100%"}},[_c('FullCalendar',{staticClass:"vc-fullcalendar",attrs:{"options":_vm.config},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [(arg.event.extendedProps.type === 'phase')?_c('div',[_c('b',[_vm._v(_vm._s(arg.timeText))]),_c('i',[_vm._v(_vm._s(arg.event.title))])]):_vm._e(),(arg.event.extendedProps.type === 'event')?_c('div',{staticClass:"event-wrapper"},[_c('c-flex',{attrs:{"w":"100%","justify":"space-between","align":"center","mb":"1"}},[_c('c-text',{attrs:{"color":"blue.400","fontSize":"sm","fontWeight":"500"}},[_vm._v(" "+_vm._s(_vm._f("moment")(arg.event.start,'hh:mm'))+" - "+_vm._s(_vm._f("moment")(arg.event.end,'hh:mm'))+" ")]),_c('c-link',[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                      fill: 'gray.500',
                      width: '14px',
                      height: '14px',
                    }),expression:"{\n                      fill: 'gray.500',\n                      width: '14px',\n                      height: '14px',\n                    }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/dots-horizontal.svg") + "#dots"}})])])],1),_c('c-heading',{attrs:{"fontWeight":"700","fontSize":"10px","maxWidth":"100%","whiteSpace":"normal"}},[_vm._v(" "+_vm._s(arg.event.title)+" ")]),_c('AppSelect',{attrs:{"options":['Going'],"value":'Going'}})],1):_vm._e(),(arg.event.extendedProps.type === 'milestone')?_c('div',{staticClass:"event-wrapper"},[_c('c-text',{attrs:{"fontSize":"9px","fontWeight":"600","color":arg.event.extendedProps.milestoneData.scenario.color}},[_vm._v(" "+_vm._s(arg.event.extendedProps.milestoneData.scenario.title)+" ")]),_c('c-flex',{attrs:{"justify":"space-between","borderWidth":"1px","p":"3","borderColor":arg.event.extendedProps.milestoneData.isAcheived
                    ? 'green.400'
                    : 'vc-orange.400',"borderRadius":"2px"}},[_c('c-heading',{attrs:{"fontWeight":"700","fontSize":"10px","maxWidth":"100%","whiteSpace":"normal"}},[_vm._v(" "+_vm._s(arg.event.title)+" ")]),(arg.event.extendedProps.milestoneData.isAcheived)?_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    width: '18px',
                    height: '18px',
                    ml: '2',
                  }),expression:"{\n                    width: '18px',\n                    height: '18px',\n                    ml: '2',\n                  }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/check-circle.svg") + "#check"}})]):_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    width: '18px',
                    height: '18px',
                    ml: '2',
                  }),expression:"{\n                    width: '18px',\n                    height: '18px',\n                    ml: '2',\n                  }"}],attrs:{"fill":"#ef5323"}},[_c('use',{attrs:{"href":require("@/assets/icons/circle.svg") + "#circle"}})])],1)],1):_vm._e()]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }