<template>
  <c-box py="8" :mx="{ base: '0', md: '0', xs: '5' }">
    <c-flex align="center" justify="space-between" mb="8">
      <c-flex align="baseline">
        <c-heading fontSize="3xl" fontWeight="700" mr="3"> Calendar </c-heading>
        <c-flex as="a" align="center">
          <c-text
            as="span"
            color="vc-orange.400"
            fontSize="md"
            mr="2"
            fontWeight="600"
          >
            Share
          </c-text>
          <svg
            v-chakra="{
              w: '4',
              h: '4',
              fill: '#ef5323',
            }"
          >
            <use href="@/assets/icons/share-box-fill.svg#share"></use>
          </svg>
        </c-flex>
      </c-flex>
      <c-link>
        <svg
          v-chakra="{
            w: '6',
            h: '6',
            fill: '#ef5323',
          }"
        >
          <use href="@/assets/icons/notification-fill.svg#notification"></use>
        </svg>
      </c-link>
    </c-flex>
    <c-grid
      w="100%"
      :template-columns="{
        base: '250px 1fr',
        md: '250px 1fr',
        xs: 'repeat(1, 1fr)',
      }"
      gap="2"
    >
      <c-stack :spacing="8">
        <c-box>
          <c-heading fontWeight="600" fontSize="sm" mb="4">
            Milestones
          </c-heading>
          <c-stack :spacing="2">
            <c-box w="100%" position="relative">
              <label
                v-chakra="{
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <!-- This is the sibling input, it's visually hidden -->
                <c-visually-hidden as="input" type="checkbox" default-checked />

                <!-- This is the control box with a check icon as children -->
                <c-control-box
                  border-width="2px"
                  size="20px"
                  rounded="sm"
                  borderColor="vc-orange.400"
                  :_checked="{
                    color: 'white',
                  }"
                  :_checkedAndChild="{
                    display: 'block',
                  }"
                  :_focus="{ boxShadow: 'none' }"
                >
                  <c-icon
                    v-chakra="{
                      display: 'none',
                    }"
                    name="check"
                    color="vc-orange.400"
                    size="12px"
                  />
                </c-control-box>

                <!-- You can pass additional text -->
                <c-box as="span" ml="3" fontSize="xs" fontWeight="500">
                  Milestones
                </c-box>
              </label>
            </c-box>
            <c-box w="100%" position="relative">
              <label
                v-chakra="{
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <!-- This is the sibling input, it's visually hidden -->
                <c-visually-hidden as="input" type="checkbox" default-checked />

                <!-- This is the control box with a check icon as children -->
                <c-control-box
                  border-width="2px"
                  size="20px"
                  rounded="sm"
                  borderColor="vc-orange.400"
                  :_checked="{
                    color: 'white',
                  }"
                  :_checkedAndChild="{
                    display: 'block',
                  }"
                  :_focus="{ boxShadow: 'none' }"
                >
                  <c-icon
                    v-chakra="{
                      display: 'none',
                    }"
                    name="check"
                    color="vc-orange.400"
                    size="12px"
                  />
                </c-control-box>

                <!-- You can pass additional text -->
                <c-box as="span" ml="3" fontSize="xs" fontWeight="500">
                  Periods
                </c-box>
              </label>
            </c-box>
            <c-box w="100%" position="relative">
              <label
                v-chakra="{
                  display: 'flex',
                  alignItems: 'center',
                }"
              >
                <!-- This is the sibling input, it's visually hidden -->
                <c-visually-hidden as="input" type="checkbox" default-checked />

                <!-- This is the control box with a check icon as children -->
                <c-control-box
                  border-width="2px"
                  size="20px"
                  rounded="sm"
                  borderColor="vc-orange.400"
                  :_checked="{
                    color: 'white',
                  }"
                  :_checkedAndChild="{
                    display: 'block',
                  }"
                  :_focus="{ boxShadow: 'none' }"
                >
                  <c-icon
                    v-chakra="{
                      display: 'none',
                    }"
                    name="check"
                    color="vc-orange.400"
                    size="12px"
                  />
                </c-control-box>

                <!-- You can pass additional text -->
                <c-box as="span" ml="3" fontSize="xs" fontWeight="500">
                  Module Reminders
                </c-box>
              </label>
            </c-box>
          </c-stack>
        </c-box>
        <c-box>
          <c-heading fontWeight="600" fontSize="sm" mb="4">
            Categories
          </c-heading>
          <c-flex>
            <c-flex
              color="blue.300"
              minWidth="120px"
              mr="2"
              mb="3"
              borderWidth="1px"
              borderRadius="4px"
              bg="blue.50"
              align="center"
              justify="center"
              h="30px"
              fontSize="xs"
              fontWeight="500"
            >
              Tag name
            </c-flex>
            <c-flex
              color="blue.300"
              minWidth="120px"
              mr="2"
              mb="3"
              borderWidth="1px"
              borderRadius="4px"
              bg="blue.50"
              align="center"
              justify="center"
              h="30px"
              fontSize="xs"
              fontWeight="500"
            >
              New Tag name
            </c-flex>
          </c-flex>
        </c-box>
        <c-box>
          <c-heading fontWeight="600" fontSize="sm" mb="4"> Type </c-heading>
          <c-flex flexWrap="wrap">
            <c-flex
              px="2"
              mr="2"
              mb="3"
              minWidth="120px"
              color="#000"
              borderWidth="1px"
              borderRadius="4px"
              bg="#fff"
              align="center"
              justify="center"
              h="30px"
              fontSize="xs"
              fontWeight="500"
              boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
            >
              Annual General Meeting
            </c-flex>
            <c-flex
              px="2"
              mr="2"
              mb="3"
              color="#000"
              minWidth="120px"
              borderWidth="1px"
              borderRadius="4px"
              bg="#fff"
              align="center"
              justify="center"
              h="30px"
              fontSize="xs"
              fontWeight="500"
              boxShadow="0px 1px 6px rgba(0, 0, 0, 0.16)"
            >
              New Tag name
            </c-flex>
          </c-flex>
        </c-box>
      </c-stack>
      <c-box>
        <c-flex justify="flex-end" mb="4">
          <c-link display="flex" alignItems="center">
            <c-text color="vc-orange.400" fontSize="sm" fontWeight="600" mr="2">
              Add
            </c-text>
            <svg
              fill="#ef5323"
              v-chakra="{
                width: '25px',
                height: '25px',
              }"
            >
              <use href="@/assets/icons/add-circle-line.svg#add-circle"></use>
            </svg>
          </c-link>
        </c-flex>
        <c-box w="100%">
          <FullCalendar class="vc-fullcalendar" :options="config">
            <template v-slot:eventContent="arg">
              <div v-if="arg.event.extendedProps.type === 'phase'">
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
              </div>
              <div
                class="event-wrapper"
                v-if="arg.event.extendedProps.type === 'event'"
              >
                <c-flex w="100%" justify="space-between" align="center" mb="1">
                  <c-text color="blue.400" fontSize="sm" fontWeight="500">
                    {{ arg.event.start | moment('hh:mm') }} -
                    {{ arg.event.end | moment('hh:mm') }}
                  </c-text>
                  <c-link>
                    <svg
                      v-chakra="{
                        fill: 'gray.500',
                        width: '14px',
                        height: '14px',
                      }"
                    >
                      <use href="@/assets/icons/dots-horizontal.svg#dots"></use>
                    </svg>
                  </c-link>
                </c-flex>
                <c-heading
                  fontWeight="700"
                  fontSize="10px"
                  maxWidth="100%"
                  whiteSpace="normal"
                >
                  {{ arg.event.title }}
                </c-heading>
                <AppSelect :options="['Going']" :value="'Going'" />
              </div>
              <div
                class="event-wrapper"
                v-if="arg.event.extendedProps.type === 'milestone'"
              >
                <c-text
                  fontSize="9px"
                  fontWeight="600"
                  :color="arg.event.extendedProps.milestoneData.scenario.color"
                >
                  {{ arg.event.extendedProps.milestoneData.scenario.title }}
                </c-text>
                <c-flex
                  justify="space-between"
                  borderWidth="1px"
                  p="3"
                  :borderColor="
                    arg.event.extendedProps.milestoneData.isAcheived
                      ? 'green.400'
                      : 'vc-orange.400'
                  "
                  borderRadius="2px"
                >
                  <c-heading
                    fontWeight="700"
                    fontSize="10px"
                    maxWidth="100%"
                    whiteSpace="normal"
                  >
                    {{ arg.event.title }}
                  </c-heading>
                  <svg
                    fill="#ef5323"
                    v-if="arg.event.extendedProps.milestoneData.isAcheived"
                    v-chakra="{
                      width: '18px',
                      height: '18px',
                      ml: '2',
                    }"
                  >
                    <use href="@/assets/icons/check-circle.svg#check"></use>
                  </svg>
                  <svg
                    fill="#ef5323"
                    v-else
                    v-chakra="{
                      width: '18px',
                      height: '18px',
                      ml: '2',
                    }"
                  >
                    <use href="@/assets/icons/circle.svg#circle"></use>
                  </svg>
                </c-flex>
              </div>
            </template>
          </FullCalendar>
        </c-box>
      </c-box>
    </c-grid>
  </c-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import { createEventId } from '@/helpers/data';

import AppSelect from './../components/AppSelect';

export default {
  components: {
    FullCalendar,
    AppSelect,
  },
  data() {
    return {
      currentEvents: [],
    };
  },
  computed: {
    ...mapState('timeline', {
      categories: (state) => state.categories,
      phases: (state) => state.phases,
      scenarios: (state) => state.scenarios,
      milestones: (state) => state.milestones,
    }),
    ...mapGetters('timeline', ['calendarEvents']),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: 'prev title next',
          center: '',
          right: 'dayGridMonth,dayGridWeek,dayGridDay,listYear',
        },
        contentHeight: 1000,
        initialView: 'dayGridMonth',
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: this.calendarEvents,
      };
    },
    calendarEventHandlers() {
      return {
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        // eventsSet: this.handleEvents,
        eventClassNames: this.createEventClassNames,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      };
    },
    config() {
      return {
        ...this.calendarOptions,
        ...this.calendarEventHandlers,
      };
    },
  },
  methods: {
    handleDateSelect(selectInfo) {
      let title = prompt('Please enter a new title for your event');
      let calendarApi = selectInfo.view.calendar;

      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },

    handleEventClick(clickInfo) {
      console.log(clickInfo);
      // if (
      //   confirm(
      //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
      //   )
      // ) {
      //   clickInfo.event.remove();
      // }
    },

    handleEvents(events) {
      this.currentEvents = events;
    },
    createEventClassNames(arg) {
      return `vc-event--${arg.event.extendedProps.type}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.vc-fullcalendar {
  ::v-deep {
    .fc-toolbar {
      &-chunk {
        @apply flex;
        .fc-button {
          background: transparent;
          border: none;
          &.fc-prev-button,
          &.fc-next-button {
            color: $color-orange;
            .fc-icon {
              font-size: 2em;
            }
          }
          &-group {
            .fc-button {
              color: $color-grey;
              text-transform: capitalize;
              &-active {
                background-color: rgba(121, 136, 146, 0.1);
                color: $color-orange;
                font-weight: 600;
                border-radius: 2px;
              }
            }
          }
        }
      }
    }
    .vc-event {
      &--event {
        span {
          @apply flex w-full;
        }
        .event-wrapper {
          @apply px-2 w-full;
        }
      }
    }
  }
}
</style>
