import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=617d61a5&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=617d61a5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617d61a5",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CLink: require('@chakra-ui/vue').CLink, CVisuallyHidden: require('@chakra-ui/vue').CVisuallyHidden, CIcon: require('@chakra-ui/vue').CIcon, CControlBox: require('@chakra-ui/vue').CControlBox, CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack, CGrid: require('@chakra-ui/vue').CGrid})
